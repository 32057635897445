import { useEffect } from 'react'
import { useBoundStore } from '../store/store'

const useTheme = () => {
  const theme = useBoundStore(state => state.common.theme)

  useEffect(() => {
    if (theme === 'light' && !document.body.classList.contains('theme-light')) {
      document.body.classList.remove('theme-dark')
      document.body.classList.add('theme-light')
    } else if (
      theme === 'dark' &&
      !document.body.classList.contains('theme-dark')
    ) {
      document.body.classList.remove('theme-light')
      document.body.classList.add('theme-dark')
    }
  }, [])
}

export default useTheme
