import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { styled } from '@linaria/react'
import { css } from '@linaria/core'

import closeModal from '../../utils/closeModal'
import Modal from './modal'

const container = css`
  max-width: 500px;
  padding: 0px;
  border-color: none;
  border: none;
  display: flex;
  background-color: transparent;
`

const Image = styled.img`
  border-radius: 10px;
  width: 100%;
  cursor: pointer;
  aspect-ratio: 1;
  @media (min-width: 1200px) {
    border-radius: 20px;
  }
`

interface BannerPopupModalProps {
  imgUrl: string
  onClick?: () => void
}

const BannerPopupModal = ({ imgUrl, onClick }: BannerPopupModalProps) => {
  const modal = useModal()

  return (
    <Modal id="banner-popup-modal" containerStyle={container} zIndex={340}>
      <Image
        src={imgUrl}
        alt="Banner"
        onClick={onClick ? onClick : () => closeModal(modal)}
      />
    </Modal>
  )
}

export default NiceModal.create(BannerPopupModal)
