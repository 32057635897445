import type { FC, ButtonHTMLAttributes } from 'react'
import { styled } from '@linaria/react'

const StyledButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  height: 45px;
  background-color: #df0033;
  color: white;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  opacity: 1;
  cursor: pointer;
  font-family: 'Syncopate-Bold';
  text-transform: uppercase;
  &:disabled {
    background-color: #65676a;
    color: #d9d9d9;
  }
  &.secondary {
    background-color: #65676a;
  }
  @media (min-width: 1200px) {
    padding: 9px 0;
    height: 50px;
    font-size: 16px;
  }
`

const ConfirmButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = props => {
  return <StyledButton {...props}>{props.children}</StyledButton>
}

export default ConfirmButton
