import { browserName, isMobile, mobileModel, isChrome } from 'react-device-detect'

export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
}

export function isIOS() {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
      'MacIntel',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

export function isHuawei() {
  const userAgent = navigator.userAgent || navigator.vendor
  return /huawei/i.test(userAgent)
}

export function getDeviceInfo() {
  let type = 1
  if (isMobile) {
    type = 2
  }
  return {
    browserName,
    mobileModel,
    userAgent: navigator.userAgent,
    type,
  }
}

export function isInsideFacebook() {
  // @ts-expect-error opera missing in window
  const ua = navigator.userAgent || navigator.vendor || window.opera
  return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1)
}

export function isCaptureSupported() {
  if (isInsideFacebook()) return false
  return isChrome || isSafari()
}
