import { styled } from '@linaria/react'

import { numberWithCommas } from '../../utils/number'
import type { AmountInputProps } from '../wallet-management/amount-input'

const Container = styled.div`
  display: flex;
  margin-bottom: 20px;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
  @media (min-width: 1200px) {
    margin-bottom: 30px;
    gap: 20px 15px;
  }
`

const Button = styled.button`
  font-family: 'Avenir-Light';
  font-size: 16px;
  color: var(--txt);
  padding: 6px 0;
  width: calc((100vw - 76px) / 3);
  background-color: var(--tertiary);
  border: 1px solid #fff;
  border-radius: 19px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  opacity: 0.7;
  &.active {
    opacity: 1;
  }
  @media (min-width: 1200px) {
    font-size: 18px;
    padding: 12px 0;
    border-radius: 26px;
    width: calc((100% - 30px) / 3);
  }
`

interface AmountSelectorProps
  extends Pick<AmountInputProps, 'amount' | 'setAmount'> {
  amounts: number[]
}

const AmountSelector = ({
  amount,
  setAmount,
  amounts,
}: AmountSelectorProps) => {
  return (
    <Container>
      {amounts.map((item, index) => (
        <Button
          onClick={() => setAmount(item)}
          className={amount === item ? 'active' : ''}
          key={index}>
          $ {numberWithCommas(item)}
        </Button>
      ))}
    </Container>
  )
}

export default AmountSelector
