import { useState, type Dispatch, type FC, type SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@linaria/react'

import type {
  WalletDetails,
  WithdrawTypes,
} from '../../types/wallet-management'

import Icon from '../icon'

const Container = styled.div`
  position: relative;
  margin-bottom: 20px;
  @media (min-width: 1200px) {
    margin-bottom: 30px;
  }
  &.open {
    .input {
      border-bottom: none;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    .dropdown {
      opacity: 1;
      pointer-events: auto;
    }
    .arrow {
      transform: translateY(-50%) rotate(90deg);
    }
  }
`

const StyledInput = styled.input`
  font-family: 'Avenir-Light';
  font-size: 16px;
  color: var(--txt);
  border: 1px solid #fff;
  padding: 0 16px;
  width: 100%;
  height: 45px;
  transition: all 0.2s ease-in-out;
  background-color: var(--tertiary);
  border-radius: 5px;
  &::placeholder {
    color: #999999;
  }
  @media (min-width: 1200px) {
    height: 55px;
    font-size: 18px;
  }
`

const Arrow = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  transition: all 0.2s ease-in-out;
  svg {
    fill: var(--txt);
  }
  @media (min-width: 1200px) {
    svg {
      width: 12px;
    }
  }
`

const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  transition: all 0.2s ease-in-out;
  background-color: var(--tertiary);
  border: 1px solid #fff;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  opacity: 0;
  pointer-events: none;
`

const DropdownItem = styled.button`
  font-family: 'Avenir-Light';
  font-size: 16px;
  color: var(--txt);
  padding: 0 16px;
  height: 45px;
  width: 100%;
  text-align: left;
  @media (min-width: 1200px) {
    height: 55px;
    font-size: 18px;
  }
`

interface SelectWalletProps {
  value: string
  type: WithdrawTypes
  walletList: WalletDetails[]
  setSelectedWallet: Dispatch<SetStateAction<WalletDetails | null>>
}

const SelectWallet: FC<SelectWalletProps> = ({
  value,
  type,
  walletList,
  setSelectedWallet,
}) => {
  const { t } = useTranslation('walletManagement')
  const [isOpen, setIsOpen] = useState(false)

  const renderList = () => {
    if (walletList.length === 0) {
      return (
        <DropdownItem onClick={() => setIsOpen(false)}>
          {t('empty')}
        </DropdownItem>
      )
    }

    return walletList.map(item => (
      <DropdownItem
        key={item.id}
        onClick={() => {
          setSelectedWallet(item)
          setIsOpen(false)
        }}>
        {item.debit_bank}
      </DropdownItem>
    ))
  }

  return (
    <Container className={'flx-ctr' + (isOpen ? ' open' : '')}>
      <StyledInput
        className="input"
        placeholder={`${t('select')} ${
          type === 'bank' ? t('bank-account') : t('usdt-address')
        }`}
        readOnly
        value={value}
        onFocus={() => setIsOpen(prev => (prev ? false : true))}
        onBlur={() => setTimeout(() => setIsOpen(false), 200)}
      />
      <Arrow className="arrow">
        <Icon id="chevron-right" width="8" />
      </Arrow>
      <Dropdown className="dropdown">{renderList()}</Dropdown>
    </Container>
  )
}

export default SelectWallet
