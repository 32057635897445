import { Route, lazy } from '@tanstack/router'

import { profileRoute } from '.'

const Kyc = lazy(() => import('../../pages/profile/kyc'))

export const kycRoute = new Route({
  getParentRoute: () => profileRoute,
  path: 'kyc',
  component: Kyc,
})
