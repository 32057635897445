import NiceModal, { useModal } from '@ebay/nice-modal-react'
import {
  MakeLinkOptions,
  useMatch,
  useNavigate,
  useSearch,
} from '@tanstack/router'
import { useEffect, useState, type ChangeEvent, type FormEvent } from 'react'
import TagManager from 'react-gtm-module'
import { useTranslation } from 'react-i18next'
import { styled } from '@linaria/react'
import { css } from '@linaria/core'

import useGeoCheck from '../../hooks/useGeoCheck'
import { useBoundStore } from '../../store/store'
import type { ApiResponse } from '../../types/api'
import type { UserInfo } from '../../types/user'
import { api } from '../../utils/api-client'
import closeModal from '../../utils/closeModal'
import { getDeviceInfo } from '../../utils/device'
import { getPublicAuth } from '../../utils/getPublicAuth'
import { encryptUser } from '../../utils/user'
import Icon from '../icon'
import { TailSpin } from '../loaders'
import Modal from './modal'
import RequestPasswordResetModal from './request-password-reset-modal'
import WalletManagementModal from '../wallet-management/wallet-management-modal'
import useDimensions from '../../hooks/useDimensions'
import DuplicateAccountModal from './duplicate-account-modal'
import SocialMediaAuth from './social-media-auth'
import ReferralCreditModal from '../referral-v2/referral-credit-modal'
import ReferNowModal from '../referral-v2/refer-now-modal'

const container = css`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: calc(100% - 32px);
`

const InputLabel = styled.label`
  width: 100%;
  display: block;
  margin-bottom: 4px;
  font-family: 'Syncopate-Bold';
  text-transform: uppercase;
  font-size: 14px;
  color: #3b3e49;
`

const StyledInput = styled.input`
  color: #3b3e49;
  font-size: 16px;
  font-family: 'Avenir-Light';
  border-radius: 4px;
  padding: 8px 10px;
  width: 100%;
  margin-bottom: 14px;
  border: 1px solid #3b3e49;
  transition: all 0.2s ease-in-out;
`

const Checkbox = styled.label`
  position: relative;
  color: #aaa;
  font-size: 12px;
  padding-left: 28px;
  margin-bottom: 16px;
  display: inline-block;
  transition: all 0.2s ease-in-out;
  font-family: 'Syncopate-Bold';
  text-transform: uppercase;
  &.checked {
    color: #3b3e49;
    span {
      border: 1px solid #3b3e49;
      &:after {
        opacity: 1;
      }
    }
  }
  input {
    display: none;
  }
  span {
    display: inline-block;
    width: 18px;
    height: 18px;
    border: 1px solid #aaa;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    padding: 3px;
    border-radius: 2px;
    transition: all 0.2s ease-in-out;
    &:after {
      content: '';
      position: absolute;
      top: 3px;
      left: 3px;
      background-color: #3b3e49;
      opacity: 0;
      transition: all 0.2s ease-in-out;
      width: 10px;
      height: 10px;
      border-radius: 2px;
    }
  }
`

const Button = styled.button`
  width: 100%;
  height: 50px;
  text-align: center;
  font-size: 14px;
  color: white;
  background-color: #0e091e;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  border: 1.5px solid #0e091e;
  font-family: 'Syncopate-Bold';
  text-transform: uppercase;
  cursor: pointer;
  &.secondary {
    color: white;
    background-color: transparent;
    border: 1.5px solid white;
  }
`

const Text = styled.p`
  font-size: 12px;
  color: #1c112b;
  margin-bottom: 5px;
  text-align: center;
  font-family: 'Syncopate-Bold';
  text-transform: uppercase;
  margin-bottom: 16px;
  span {
    font-family: 'Syncopate-Bold';
    text-transform: uppercase;
  }
  &.forgot {
    color: #848292;
    cursor: pointer;
  }
`

const PasswordIcon = styled.div`
  position: absolute;
  top: calc(50% - 5px);
  right: 8px;
  transform: translateY(-50%);
  display: flex;
  cursor: pointer;
`

const Banner = styled.button`
  width: 100%;
  display: flex;
  margin-bottom: 24px;
  img {
    width: 100%;
    border-radius: 10px;
  }
`

const ErrorMessage = styled.p`
  font-size: 14px;
  font-family: 'Avenir-Light';
  color: rgb(255, 64, 88);
  width: 100%;
  margin-bottom: 10px;
  padding: 2px 0;
  @media (min-width: 1200px) {
    font-size: 16px;
  }
`

const LoginModal = () => {
  const affiliateId = (useMatch().params as { affiliate?: string }).affiliate
  const { isDesktop } = useDimensions()
  const { t, i18n } = useTranslation(['login', 'walletManagement'])
  const { geoCheck } = useGeoCheck()
  const { language } = i18n
  const { redirectUrl } = useSearch() as { redirectUrl?: string }
  const setUserStore = useBoundStore(state => state.user.setUser)
  const enableSocialMediaAuth = useBoundStore(
    state => state.settings.social_media_auth,
  )
  const modal = useModal()
  const requestPasswordResetModal = useModal(RequestPasswordResetModal)
  const walletManagementModal = useModal(WalletManagementModal)
  const duplicateModal = useModal(DuplicateAccountModal)
  const referralCreditModal = useModal(ReferralCreditModal)
  const referNowModal = useModal(ReferNowModal)
  const navigate = useNavigate()
  const [user, setUser] = useState({
    username: '',
    password: '',
  })
  const [isRemember, setIsRemember] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState('')
  const [hidePassword, setHidePassword] = useState(true)

  useEffect(() => {
    if (modal.visible) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'login_form_open',
          event_category: 'login',
          event_action: 'click_open',
          event_label: 'Open Login Form',
        },
      })
    }
  }, [modal.visible])

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUser(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const onSuccess = async (info: UserInfo, status: number) => {
    if (status === 1) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'user_login',
          event_category: 'login',
          event_action: 'action_complete',
          event_label: 'Login Success',
          user_id: info.username,
        },
      })
    }
    if (status === 2) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'registration_success',
          event_category: 'register',
          event_action: 'action_complete',
          event_label: 'Registration Third Step',
        },
      })
      kwaiq.instance('488334558124585026').track('completeRegistration')
    }
    if (
      location.pathname.includes('/sorteos') ||
      location.pathname.includes('/sports')
    ) {
      location.reload()
    } else {
      if (redirectUrl) {
        navigate({ to: redirectUrl, search: {} } as MakeLinkOptions<'/'>)
        await closeModal(modal)
        return
      }

      if (isDesktop) {
        walletManagementModal.show({})
        location.pathname.includes('/register') && navigate({ to: '/' })
      } else {
        navigate({ to: '/deposit' })
      }
      if (info.coin_drop === 1) {
        referralCreditModal.show()
      } else {
        referNowModal.show()
      }
    }

    await closeModal(modal)
  }

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!user.username || !user.password) return

    setError('')
    setIsLoading(true)

    const publicAuth = await getPublicAuth()

    const reqBody = {
      ...user,
      username: user.username.toLowerCase(),
      submit_type: 'login',
      meta: getDeviceInfo(),
      auth: publicAuth,
    }

    const response = await api.post<ApiResponse<UserInfo>>(
      '/center.php',
      reqBody,
    )

    const { status, info } = response.data

    if (status === 1) {
      const privateAuth = await api.post<
        ApiResponse<string | { auth: string; expired_time: number }>
      >('/link.php', {
        username: info.username,
        password: user.password,
        auth: publicAuth,
      })
      if (
        privateAuth.data.status === 1 &&
        typeof privateAuth.data.info !== 'string'
      ) {
        localStorage.setItem('privateAuth', privateAuth.data.info.auth)
      } else {
        if (typeof privateAuth.data.info === 'string') {
          setError(privateAuth.data.info)
        }
        setIsLoading(false)
        return
      }
      encryptUser({ username: info.username, password: user.password })
      setUserStore(info)
      onSuccess(info, 1)
    } else if (status === 2) {
      if (typeof info === 'string') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        navigate({ to: info })
        await closeModal(modal)
      }
    } else if (status === 3) {
      duplicateModal.show()
      await closeModal(modal)
    } else {
      if (typeof info === 'string') setError(info as unknown as string)
    }
    setIsLoading(false)
  }

  return (
    <Modal id="log-in-modal" containerStyle={container}>
      <Banner>
        <img
          src={`https://www.u2d8899.com/mexicoimages/banner/login_banner_${language}.webp`}
        />
      </Banner>
      <form onSubmit={onSubmit}>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <InputLabel>{t('username-or-email')}</InputLabel>
        <StyledInput name="username" onChange={onChange} />
        <InputLabel>{t('password')}</InputLabel>
        <div className="flx-ctr" style={{ position: 'relative' }}>
          <StyledInput
            style={{ paddingRight: '30px' }}
            name="password"
            type={hidePassword ? 'password' : 'text'}
            onChange={onChange}
          />
          <PasswordIcon
            onClick={() => setHidePassword(prev => (prev ? false : true))}>
            {hidePassword ? (
              <Icon id="eye-slash" width="20" height="20" stroke="#888" />
            ) : (
              <Icon id="eye" width="20" height="20" stroke="#0e091e" />
            )}
          </PasswordIcon>
        </div>
        <Checkbox className={isRemember ? 'checked' : ''}>
          {t('remember-me')}
          <input
            type="checkbox"
            checked={isRemember}
            onChange={e => setIsRemember(e.target.checked)}
          />
          <span />
        </Checkbox>
        <Text
          className="forgot"
          onClick={async () => {
            requestPasswordResetModal.show()
            await closeModal(modal)
          }}>
          <span style={{ textDecoration: 'underline' }}>
            {t('forgot-password')}
          </span>
        </Text>
        <button
          type="button"
          style={{ width: '100%' }}
          onClick={async () => {
            geoCheck(() =>
              navigate({
                to: '/register',
                search:
                  typeof affiliateId === 'string'
                    ? {
                        utm_source: 'affiliates',
                        utm_medium: 'influencers',
                        utm_campaign: affiliateId,
                      }
                    : undefined,
              }),
            )
            await closeModal(modal)
          }}>
          <Text>{t('no-account')}</Text>
        </button>
        <div className="flx-ctr">
          {isLoading ? (
            <TailSpin width={39} height={39} color="#0e091e" />
          ) : (
            <Button style={{ margin: '0 auto' }} type="submit">
              {t('login')}
            </Button>
          )}
        </div>
      </form>
      {enableSocialMediaAuth.value === 1 && (
        <SocialMediaAuth
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setError={message => setError(message)}
          callback={onSuccess}
        />
      )}
    </Modal>
  )
}

export default NiceModal.create(LoginModal)
