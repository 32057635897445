import Modal from '../common/modal'
import { css } from '@linaria/core'
import referNowBg from '../../assets/referral/refer_now_bg.webp'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { styled } from '@linaria/react'
import closeModal from '../../utils/closeModal'
import Icon from '../icon'
import { useTranslation } from 'react-i18next'
import { Link } from '@tanstack/router'

const container = css`
  padding: 75px 40px 40px;
  border: none;
  background: url(${referNowBg});
  background-size: contain;
  background-repeat: repeat;
  aspect-ratio: 585 / 837;
  width: 350px;
  @media (min-width: 1200px) {
    padding-top: 88px;
    width: 450px;
  }
`

const Close = styled.button`
  display: flex;
  position: absolute;
  padding: 16px;
  top: 0;
  right: 0;
  &:hover {
    opacity: 0.8;
  }
  @media (min-width: 1200px) {
    padding: 20px;
  }
`

const PrimaryTxt = styled.p`
  font-family: 'Geometr706-BlkCn-BT';
  text-transform: uppercase;
  font-size: 26px;
  color: #ffce00;
  text-shadow:
    1px 1px 2px #000,
    1px 2px #fff;
  text-align: center;
  @media (min-width: 1200px) {
    font-size: 28px;
  }
`

const SecondaryTxt = styled.p`
  font-family: 'Geometr706-BlkCn-BT';
  font-size: 26px;
  color: #fff;
  text-shadow: 5px 5px 10px #0004b3;
  text-align: center;
  @media (min-width: 1200px) {
    font-size: 28px;
  }
`

const ClickHere = styled(Link)`
  height: 35px;
  background-color: #df0033;
  border-radius: 2px;
  padding: 0 24px;
  display: block;
  margin: 16px auto 0;
  width: max-content;
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  p {
    font-size: 12px;
    line-height: 35px;
    color: #fff;
    font-family: 'Syncopate-Bold';
    text-transform: uppercase;
  }
  @media (min-width: 1200px) {
    height: 40px;
    p {
      font-size: 14px;
      line-height: 40px;
    }
  }
`

const ReferNowModal = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation(['referral', 'blog'])
  const modal = useModal()
  return (
    <Modal id="refer-now-modal" containerStyle={container} zIndex={356}>
      <Close onClick={() => closeModal(modal)}>
        <Icon id="close" width="15" height="15" fill="#fff" />
      </Close>
      <PrimaryTxt>{t('refer-a-friend')}</PrimaryTxt>
      <PrimaryTxt style={{ marginBottom: 12 }}>{t('extra-cash')}</PrimaryTxt>
      <SecondaryTxt>{t('refer-now')}</SecondaryTxt>
      <SecondaryTxt style={{ color: '#ffce00' }}>
        {t('percent-per-deposit')}
      </SecondaryTxt>
      <SecondaryTxt>{t('referred-friend')}</SecondaryTxt>
      <ClickHere
        to="/promotions/$promoId"
        params={{
          promoId:
            language === 'en'
              ? '116-refer-your-friends-and-get-rewarded'
              : '121-refiere-a-tus-amigos-y-obtén-una-recompensa',
        }}
        onClick={() => closeModal(modal)}>
        <p>{t('blog:more-info')}</p>
      </ClickHere>
    </Modal>
  )
}

export default NiceModal.create(ReferNowModal)
