import { Route, lazy } from '@tanstack/router'

import { affiliateRoute } from '.'

const Affiliate = lazy(
  () => import('../../../pages/profile/affiliate/affiliate'),
)

export const affiliateIndexRoute = new Route({
  getParentRoute: () => affiliateRoute,
  path: '/',
  component: Affiliate,
})
