import { Route, lazy } from '@tanstack/router'

import { withdrawRoute } from '.'

const Withdraw = lazy(() => import('../../pages/withdraw'))

export const withdrawIndexRoute = new Route({
  getParentRoute: () => withdrawRoute,
  path: '/',
  component: Withdraw,
})
