import { Portal } from 'react-portal'
import { styled } from '@linaria/react'

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 600;
  background-color: rgba(14, 9, 30, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  .flx {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    width: 180px;
    margin-bottom: 24px;
  }
  @media (min-width: 1200px) {
    img {
      width: 250px;
    }
  }
  &.solid-bg {
    background-color: rgba(14, 9, 30, 1);
  }
`

const LeftBar = styled.div`
  height: 8px;
  border-radius: 4px;
  @keyframes leftFluctuate {
    0% {
      width: 25px;
      background-color: #df0033;
    }
    50% {
      width: 8px;
      background-color: #fff;
    }
    100% {
      width: 25px;
      background-color: #df0033;
    }
  }
  animation: leftFluctuate 1s linear infinite;
`

const RightBar = styled.div`
  height: 8px;
  border-radius: 4px;
  @keyframes rightFluctuate {
    0% {
      width: 8px;
      background-color: #fff;
    }
    50% {
      width: 25px;
      background-color: #df0033;
    }
    100% {
      width: 8px;
      background-color: #fff;
    }
  }
  animation: rightFluctuate 1s linear infinite reverse;
`

interface LoadingProps {
  solidBg?: boolean
}

const Loading = ({ solidBg }: LoadingProps) => {
  return (
    <Portal>
      <Container className={solidBg ? 'solid-bg' : ''}>
        <div className="flx" style={{ flexDirection: 'column' }}>
          <img src={`https://www.u2d8899.com/mexicoimages/logo/dark.png`} alt="App logo" />
          <div className="flx" style={{ gap: '3px' }}>
            <LeftBar />
            <RightBar />
          </div>
        </div>
      </Container>
    </Portal>
  )
}

export default Loading
