import { lazy } from 'react'
import type { Router } from '@tanstack/router'

const DevTools = import.meta.env.PROD
  ? () => null
  : lazy(() =>
      import('@tanstack/router-devtools').then(res => ({
        default: res.TanStackRouterDevtools,
      })),
    )

function TanstackRouterDevTools({ router }: { router: Router }) {
  return (
    <DevTools position="bottom-left" router={router} initialIsOpen={false} />
  )
}

export default TanstackRouterDevTools
