import { useModal } from '@ebay/nice-modal-react'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'

import LoginModal from '../components/common/log-in-modal'
import WalletManagementModal from '../components/wallet-management/wallet-management-modal'
import useDimensions from './useDimensions'
import useGeoCheck from './useGeoCheck'
import { decryptUser } from '../utils/user'

function useContinue() {
  const logInModal = useModal(LoginModal)
  const walletManagementModal = useModal(WalletManagementModal)
  const { isDesktop } = useDimensions()
  const { geoCheck } = useGeoCheck()
  const params = new URLSearchParams(window.location.search)

  useEffect(() => {
    const continueTo = params.get('continue')
    const user = decryptUser()

    if (!continueTo) return
    if (!user) {
      geoCheck(logInModal.show)
      return
    }

    if (isDesktop) {
      if (continueTo === 'deposit') {
        TagManager.dataLayer({
          dataLayer: {
            event: 'deposit_form_open',
            event_category: 'deposit_funds',
            event_action: 'click_open',
            event_label: 'Deposit form Open',
            user_id: user.username,
          },
        })
        walletManagementModal.show({})
      }
    } else {
      if (continueTo === 'deposit') {
        TagManager.dataLayer({
          dataLayer: {
            event: 'deposit_form_open',
            event_category: 'deposit_funds',
            event_action: 'click_open',
            event_label: 'Deposit form Open',
            user_id: user.username,
          },
        })
        window.location.href = '/deposit'
      }
    }
  }, [params])
}

export default useContinue
