import { Route, lazy } from '@tanstack/router'

import { rootRoute } from './root'

const Home = lazy(() => import('../pages/home'))

type IndexSearch = {
  bonus?: string
  reset_password?: string
  redirectUrl?: string
  referral?: string
}

export const indexRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/',
  component: Home,
  validateSearch: (search: Record<string, unknown>): IndexSearch => {
    return {
      bonus: typeof search.bonus === 'string' ? search.bonus : '',
      reset_password:
        typeof search.reset_password === 'string' ? search.reset_password : '',
      redirectUrl: typeof search.redirectUrl === 'string' ? search.redirectUrl : '',
      referral: typeof search.referral === 'string' ? search.referral : '',
    }
  },
})
