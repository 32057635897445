import { Route, lazy } from '@tanstack/router'
import { profileRoute } from '.'

const ClaimHistory = lazy(() => import('../../pages/claim-history'))

export const claimHistoryRoute = new Route({
  getParentRoute: () => profileRoute,
  path: 'claim-history',
  component: ClaimHistory,
})
