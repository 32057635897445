import { useTranslation } from 'react-i18next'
import { styled } from '@linaria/react'

import Icon from '../icon'

const Container = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;
`

const Tooltip = styled.span`
  transition: all 0.2s ease-in-out;
  position: absolute;
  bottom: calc(100% + 5px);
  transform: translateX(-50%);
  left: 50%;
  background-color: #df0033;
  border-radius: 4px;
  padding: 10px;
  opacity: 0;
  pointer-events: none;
  p {
    font-size: 13px;
    color: #fff;
    white-space: nowrap;
  }
  @media (min-width: 1200px) {
    p {
      font-size: 15px;
    }
  }
`

const Text = styled.p`
  font-family: 'Avenir-Medium';
  font-size: 15px;
  color: #65676a;
  display: flex;
  align-items: center;
  position: relative;
  svg {
    margin-right: 6px;
    margin-bottom: 2px;
  }
  &:hover {
    ${Tooltip} {
      opacity: 1;
    }
  }
`

const AboutWithdraw = () => {
  const { t } = useTranslation('walletManagement')

  return (
    <Container>
      <Text>
        <Icon id="outline-exclamation-circle" width="15" height="15" />
        {t('about-withdrawal')}
        <Tooltip>
          <p>{t('transaction-time')}</p>
          <p>{t('no-commission')}</p>
          <p>{t('minimum-withdrawal')}</p>
        </Tooltip>
      </Text>
    </Container>
  )
}

export default AboutWithdraw
