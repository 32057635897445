import { useModal } from '@ebay/nice-modal-react'
import { useEffect } from 'react'

import { AnnouncementModal } from '../components/common'
import type { AnnouncementObj } from '../types/announcement'
import type { ApiResponse } from '../types/api'
import { api } from '../utils/api-client'

const useAnnouncements = () => {
  const announcementModal = useModal(AnnouncementModal)

  useEffect(() => {
    const fetchAnnouncements = async () => {
      const response = await api.post<ApiResponse<AnnouncementObj[]>>(
        '/ajax_check.php',
        {
          type: 'get_notice',
          num: 10,
        },
      )

      const { status, info } = response.data
      if (status === 1) {
        const popupAnnouncement = info.find(
          announcement => announcement.is_popup === '1',
        )

        if (popupAnnouncement === undefined) return null

        announcementModal.show({ announcement: popupAnnouncement })
      }
    }
    fetchAnnouncements()
  }, [])
}

export default useAnnouncements
