import { Route, lazy } from '@tanstack/router'

import { rootRoute } from './root'
import { Game } from '../types/game'

const Game = lazy(() => import('../pages/game'))
  

export const gameRoute = new Route({
  getParentRoute: () => rootRoute,
  path: 'game',
  component: Game,
  validateSearch: (search: Record<string, unknown>): Game['gameInfo'] => {
    return search.gameInfo as Game['gameInfo']
  },
})
