import { useEffect } from 'react'

import { useBoundStore } from '../store/store'
import type { SettingsSliceStates } from '../store/types/settings'
import { api } from '../utils/api-client'

const useSettings = () => {
  const setSettings = useBoundStore(state => state.settings.setSettings)

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const res = await api.get<SettingsSliceStates>(`/settings.php`)
        if (res.data) {
          setSettings(res.data)
        }
      } catch (error) {
        console.error(error)
      }
    }
    fetchSettings()
  }, [])
}

export default useSettings
