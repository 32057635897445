import { Route, lazy } from '@tanstack/router'

import { profileRoute } from '.'

const Inbox = lazy(() => import('../../pages/profile/inbox'))

export const inboxRoute = new Route({
  getParentRoute: () => profileRoute,
  path: 'inbox',
  component: Inbox,
})
