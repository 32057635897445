import { useModal } from '@ebay/nice-modal-react'
import { useEffect } from 'react'
import { isDesktop, isIOS } from 'react-device-detect'

import { AndroidDownloadBanner } from '../components/common'

const useAndroidDownloadBanner = () => {
  const androidDownloadBanner = useModal(AndroidDownloadBanner)

  useEffect(() => {
    if (
      isIOS ||
      isDesktop ||
      window.ReactNativeWebView ||
      location.pathname !== '/'
    )
      return

    androidDownloadBanner.show({
      downloadUrl: 'https://play.google.com/store/apps/details?id=com.mexplay_rn',
    })
  }, [])
}

export default useAndroidDownloadBanner
