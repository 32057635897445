import { Route, lazy } from '@tanstack/router'

import { promotionsRoute } from '.'

const PromoTermsAndConditions = lazy(
  () => import('../../pages/promotions/promo-terms-and-conditions'),
)

export const promoTermsRoute = new Route({
  getParentRoute: () => promotionsRoute,
  path: '$promoId',
  component: PromoTermsAndConditions,
})
