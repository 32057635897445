import { useRouter } from '@tanstack/router'
import { useTranslation } from 'react-i18next'
import { styled } from '@linaria/react'

import useDimensions from '../../hooks/useDimensions'
import Icon from '../icon'

const Container = styled.div`
  position: relative;
  padding-left: 50px;
  margin-bottom: 30px;
  @media (min-width: 1200px) {
    padding-left: 24px;
    margin-bottom: 24px;
  }
`

const Back = styled.div`
  position: absolute;
  top: 15px;
  left: 12px;
  display: flex;
`

const Badge = styled.div`
  display: flex;
  position: absolute;
  top: 10px;
  right: 15px;
  pointer-events: none;
  @media (min-width: 1200px) {
    top: 10px;
    right: 24px;
    svg {
      width: 45px;
    }
  }
`

const Tab = styled.div`
  font-family: 'Syncopate-Bold';
  font-size: 14px;
  color: var(--txt);
  width: 240px;
  padding: 27px 20px 0;
  @media (min-width: 1200px) {
    font-size: 18px;
    padding: 27px 0 0;
    width: 270px;
  }
`

const Header = () => {
  const { isDesktop } = useDimensions()
  const { t } = useTranslation('announcementModal')
  const { history } = useRouter()

  return (
    <Container>
      {!isDesktop && (
        <Back onClick={() => history.back()}>
          <Icon id="back" width="35" height="35" />
        </Back>
      )}
      <Badge>
        <Icon id="announcement" width="40" height="43" />
      </Badge>
      <Tab>{t('latest-announcements')}</Tab>
    </Container>
  )
}

export default Header
