import NiceModal, { useModal } from '@ebay/nice-modal-react'
import type { ReactNode } from 'react'
import { styled } from '@linaria/react'

import closeModal from '../../utils/closeModal'
import Modal from './modal'

const Button = styled.button`
  width: 120px;
  text-align: center;
  font-size: 18px;
  padding: 7px 0;
  background-color: var(--ok-btn-bg);
  border: 1.5px solid var(--ok-btn-bg);
  color: var(--ok-btn-txt);
  border-radius: 22px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  font-family: 'Avenir-Light';
  @media (min-width: 1200px) {
    font-size: 20px;
    padding: 10px 0;
    width: 170px;
    border-radius: 26px;
  }
`

const Title = styled.p`
  color: var(--txt);
  font-size: 24px;
  font-family: 'Avenir-Black';
  margin-bottom: 20px;
  text-align: center;
  @media (min-width: 1200px) {
    font-size: 30px;
  }
`

const Text = styled.p`
  font-size: 15px;
  color: var(--txt);
  text-align: center;
  font-family: 'Avenir-Light';
  span {
    font-family: 'Avenir-Heavy';
  }
  @media (min-width: 1200px) {
    font-size: 20px;
  }
`

type MessageModalType = {
  message?: ReactNode
  title?: string
  onClick?: (() => void) | null
}

const MessageModal = ({ title, message, onClick }: MessageModalType) => {
  const modal = useModal()

  return (
    <Modal id="message-modal" zIndex={335}>
      {title && <Title>{title}</Title>}
      {message && <Text>{message}</Text>}
      <div className="flx-ctr" style={{ marginTop: '25px' }}>
        <Button
          onClick={async () => {
            onClick?.()
            await closeModal(modal)
          }}>
          Ok
        </Button>
      </div>
    </Modal>
  )
}

export default NiceModal.create(MessageModal)
