import { useModal } from '@ebay/nice-modal-react'
import { useEffect, type PropsWithChildren } from 'react'
import { Portal } from 'react-portal'
import { css, cx } from '@linaria/core'
import { LinariaClassName } from '@linaria/core'
import Backdrop from './backdrop'
import closeModal from '../../utils/closeModal'

const defaultStyles = css`
  @keyframes fadeIn {
    0% {
      transform: translate(-50%, calc(-50% - 50px));
      opacity: 0;
    }
    100% {
      transform: translate(-50%, -50%);
      opacity: 1;
    }
  }
  position: fixed;
  top: 50%;
  left: 50%;
  width: calc(100% - 60px);
  max-width: 400px;
  padding: 35px 20px 30px;
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
  animation: fadeIn 0.3s ease-in-out;
  background-color: var(--primary);
  border: 2px solid var(--tertiary);
`

type ModalProps = PropsWithChildren<{
  id: string
  containerStyle?: LinariaClassName
  zIndex?: number
  onModalClose?: () => void
  disableOverlayClose?: boolean
}>

const Modal = ({
  children,
  containerStyle,
  id,
  zIndex,
  onModalClose,
  disableOverlayClose,
}: ModalProps) => {
  const modal = useModal()

  const isVisible = modal.visible

  useEffect(() => {
    isVisible
      ? document.body.classList.add('noscroll')
      : document.body.classList.remove('noscroll')
  }, [isVisible])

  return (
    <Portal>
      <Backdrop
        id={id}
        isVisible={isVisible}
        onClick={() => {
          onModalClose?.()
          !disableOverlayClose && closeModal(modal)
        }}
        zIndex={zIndex ?? 203}
      />
      <div
        className={cx(defaultStyles, containerStyle)}
        style={{
          zIndex: zIndex ? zIndex + 1 : 204,
          transform: isVisible ? 'translate(-50%, -50%)' : 'translate(-50%, calc(-50% - 50px))',
          opacity: isVisible ? 1 : 0,
          pointerEvents: isVisible ? 'auto' : 'none',
        }}>
        {children}
      </div>
    </Portal>
  )
}

export default Modal
