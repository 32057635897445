import type { StateCreator } from 'zustand'

import type { AllSlices } from '../types'
import type { SportsSlice } from '../types/sports'

export const createSportsSlice: StateCreator<
  AllSlices,
  [],
  [],
  SportsSlice
> = set => ({
  sportsParams: {
    token: null,
    brandId: null,
    themeName: 'default',
    dgJs: '',
  },
  setSportsParams: sportsParams =>
    set(state => ({ sports: { ...state.sports, sportsParams } })),
})
