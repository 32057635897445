import { LiveChatWidget } from '@livechat/widget-react'
import { styled } from '@linaria/react'

import customerService from '../../assets/common/customer_service.svg'
import { useBoundStore } from '../../store/store'

const Container = styled.button`
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  display: flex;
  * {
    pointer-events: none;
  }
  &:hover {
    transform: scale(1.05);
  }
`

export const CustomerServiceButton = () => {
  return (
    <Container
      id="float-customer-service"
      aria-label="Contact customer service">
      <img
        src={customerService}
        style={{ width: 50, height: 51.66 }}
        alt="customer service button"
      />
    </Container>
  )
}

const CustomerService = () => {
  const email = useBoundStore(state => state.user.email)
  const isChatVisible = useBoundStore(state => state.common.isChatVisible)
  const setIsChatVisible = useBoundStore(state => state.common.setIsChatVisible)
  const username = useBoundStore(state => state.user.username)

  return (
    <LiveChatWidget
      license="13100400"
      visibility={isChatVisible ? 'maximized' : 'minimized'}
      customerName={username ?? ''}
      customerEmail={email ?? ''}
      onVisibilityChanged={data =>
        setIsChatVisible(data.visibility === 'maximized')
      }
    />
  )
}

export default CustomerService
