import { useMatches } from '@tanstack/router'
import { useEffect } from 'react'

export default function useScrollToTop() {
  const matches = useMatches()
  const fullPath = matches.slice(-1)[0].route.fullPath as string

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [fullPath])

  return null
}
