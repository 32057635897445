import { useModal } from '@ebay/nice-modal-react'
import { styled } from '@linaria/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useBoundStore } from '../../store/store'
import type { ApiResponse } from '../../types/api'
import { api } from '../../utils/api-client'
import closeModal from '../../utils/closeModal'
import twoDigitsFormat from '../../utils/twoDigitsFormat'
import Icon from '../icon'
import { TailSpin } from '../loaders'

const EmailInput = styled.div`
  background-color: var(--tertiary);
  border-radius: 4px;
  margin-bottom: 12px;
  &.code {
    width: 220px;
  }
  input {
    flex: 1;
    color: var(--txt);
    padding: 10px;
    font-family: 'Avenir-Light';
    font-size: 16px;
    &::placeholder,
    &:read-only {
      color: #999;
    }
  }
  @media (min-width: 1200px) {
    input {
      padding: 14px 10px;
    }
  }
`

const Text = styled.p`
  font-size: 14px;
  font-family: 'Avenir-Light';
  color: #999;
  margin-bottom: 12px;
  @media (min-width: 1200px) {
    font-size: 16px;
  }
`

const SendCode = styled.button`
  min-width: 20px;
  margin-right: 10px;
  &.can-send {
    svg {
      fill: #df0033;
    }
  }
  @media (min-width: 1200px) {
    min-width: 25px;
    svg {
      width: 25px;
    }
  }
`

const Timer = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  font-size: 11px;
  font-family: 'Avenir-Heavy';
  color: #999;
  margin-bottom: 12px;
  margin-left: 12px;
  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    z-index: 1;
  }
  @media (min-width: 1200px) {
    width: 37px;
    height: 37px;
    font-size: 13px;
    svg {
      width: 37px;
      height: 37px;
    }
  }
`

const Message = styled.p`
  font-family: 'Avenir-Light';
  font-size: 14px;
  margin-bottom: 12px;
  &.error {
    color: rgb(255, 64, 88);
  }
  &.success {
    color: #4bca81;
  }
  @media (min-width: 1200px) {
    font-size: 16px;
  }
`

const ConfirmBtn = styled.button`
  width: 100%;
  line-height: 45px;
  height: 45px;
  border-radius: 5px;
  color: #fff;
  font-family: 'Syncopate-Bold';
  text-transform: uppercase;
  font-size: 15px;
  background-color: #df0033;
  margin-top: 12px;
  &:disabled {
    background-color: #65676a;
  }
`

interface EmailVerificationProps {
  isVisible: boolean
  inModal?: boolean
}

const EmailVerification = ({ isVisible, inModal }: EmailVerificationProps) => {
  const email_verified = useBoundStore(state => state.user.email_verified)
  const defaultEmail = useBoundStore(state => state.user.email)
  const setEmailVerification = useBoundStore(
    state => state.user.setEmailVerification,
  )
  const theme = useBoundStore(state => state.common.theme)
  const emailVerificationModal = useModal()
  const { t } = useTranslation(['personalData', 'register'])
  const [email, setEmail] = useState(defaultEmail)
  const [message, setMessage] = useState<null | {
    message: string
    isValid: boolean
  }>(null)
  const [timer, setTimer] = useState(30)
  const [isSending, setIsSending] = useState(false)
  const [code, setCode] = useState('')
  const [isVerified, setIsVerified] = useState(email_verified === '1')
  const [isVerifying, setIsVerifying] = useState(false)
  const [codeStatus, setCodeStatus] = useState<
    'valid' | 'not-valid' | 'validating'
  >('validating')

  const sendCode = async () => {
    setMessage(null)

    const reqBody = {
      type: 'send_verification_email',
      email,
    }

    setIsSending(true)
    const response = await api.post<ApiResponse<string>>(
      '/request.php',
      reqBody,
    )
    const { status, info } = response.data
    if (status === 1) {
      setMessage({ message: info, isValid: true })
      const interval = setInterval(() => {
        setTimer(prev => {
          if (prev - 1 >= 0) return prev - 1
          clearInterval(interval)
          return 30
        })
      }, 1000)
    } else {
      setMessage({ message: info, isValid: false })
    }
    setIsSending(false)
  }

  const renderCodeStatus = () => {
    if (isVerifying)
      return (
        <TailSpin
          color={theme === 'dark' ? '#fff' : '#0E091E'}
          width={20}
          height={20}
        />
      )
    if (codeStatus === 'valid')
      return (
        <Icon
          id="check-2"
          width="25"
          height="19"
          fill="#00C850"
          opacity="0.8"
        />
      )
    if (codeStatus === 'not-valid')
      return (
        <Icon
          id="close-2"
          width="23"
          height="23"
          fill="#B60016"
          opacity="0.8"
        />
      )

    return null
  }

  useEffect(() => {
    setCodeStatus('validating')
    if (code.length !== 6) return
    const reqBody = {
      type: 'verify_email_code',
      code,
      email,
    }

    setIsVerifying(true)
    api.post('/request.php', reqBody).then(res => {
      const { status } = res.data as ApiResponse<string>
      if (status === 1) {
        setCodeStatus('valid')
        setEmailVerification('1')
      } else {
        setCodeStatus('not-valid')
      }
      setIsVerifying(false)
    })
  }, [code])

  useEffect(() => {
    setIsVerified(email_verified === '1')
  }, [isVisible])

  if (isVerified) return null

  return (
    <div>
      {message && (
        <Message className={message.isValid ? 'success' : 'error'}>
          {message.message}
        </Message>
      )}
      <Text>({t('register:check-spam')})</Text>
      <div className="flx-ctr">
        <EmailInput className="flx-ctr" style={{ flex: 1 }}>
          <input
            readOnly
            placeholder={t('email-address')}
            value={`${email.slice(0, 4)}****${email.slice(9)}`}
            onChange={e => setEmail(e.target.value)}
          />
          {isSending ? (
            <div className="flx-ctr" style={{ paddingRight: '10px' }}>
              <TailSpin
                color={theme === 'dark' ? '#fff' : '#0E091E'}
                width={20}
                height={20}
              />
            </div>
          ) : (
            <SendCode
              className={`flx-ctr ${email && timer === 30 ? 'can-send' : ''}`}
              onClick={sendCode}
              disabled={!(email && timer === 30)}>
              <Icon id="send" width="20" height="19" fill="#999" />
            </SendCode>
          )}
        </EmailInput>
        <Timer className="flx-ctr">
          {twoDigitsFormat(timer)}s
          <Icon id="refresh-2" width="37" height="37" stroke="#999" />
        </Timer>
      </div>
      <div className="flx-str-ctr">
        <EmailInput className="flx-ctr code">
          <input
            placeholder={t('email-verification-code')}
            maxLength={6}
            type="number"
            inputMode="numeric"
            value={code}
            onChange={e => setCode(e.target.value.replace(/\D/g, ''))}
          />
        </EmailInput>
        <div
          className="flx-ctr"
          style={{ marginBottom: '12px', marginLeft: '12px' }}>
          {renderCodeStatus()}
        </div>
      </div>
      {inModal && (
        <div className="flx-ctr">
          <ConfirmBtn
            disabled={email_verified === '0'}
            onClick={() => closeModal(emailVerificationModal)}>
            {t('confirm')}
          </ConfirmBtn>
        </div>
      )}
    </div>
  )
}

export default EmailVerification
