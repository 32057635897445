import { Component } from 'react'

import { ErrorPage } from '../components/common'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, errorMessage: '' }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error) {
    if (error.message) {
      this.setState({ errorMessage: error.message })
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorPage errorMessage={this.state.errorMessage} />
    }

    // eslint-disable-next-line react/prop-types
    return <>{this.props.children}</>
  }
}

export default ErrorBoundary
