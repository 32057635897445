// Convert a number to a string with commas
export function numberWithCommas(x: number) {
  const decimal = x - Math.floor(x)
  if (decimal > 0) {
    return (
      Math.floor(x)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + decimal.toFixed(2).slice(1)
    )
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function generateRandomInteger(max: number) {
  return Math.floor(Math.random() * max) + 1
}

export function clamp(num: number, max: number) {
  return num > max ? max : num
}

export function generateRandomNumber(min: number, max: number) {
  const difference = max - min

  // generate random number 
  let rand = Math.random()

  // multiply with difference 
  rand = rand * difference

  // add with min value 
  rand = rand + min

  return rand
}
