import { AxiosRequestConfig } from 'axios'
import qs from 'qs'

const COMMON_API_LIST = [
  'avatar_verify',
  'bank_verify',
  'change_information',
  'game_list',
  'regist',
  'reset_password',
  'send_reset_password',
  'send_verification_email',
  'send_verification_sms',
  'upload_pictures',
  'verify_email_code',
  'verify_reset_password',
  'verify_sms_code',
  'washcodeself_receive',
  'verify_parameters',
]

export function isCommonApi(requestConfig: AxiosRequestConfig) {
  const { data, url } = requestConfig

  const type =
    data instanceof FormData
      ? data.get('type')
      : typeof data === 'string'
      ? qs.parse(data).type
      : ''

  const submitType =
    data instanceof FormData
      ? data.get('submit_type')
      : typeof data === 'string'
      ? qs.parse(data).submit_type
      : ''

  if (url?.includes('/oauth')) return true

  if (typeof type === 'string') {
    return COMMON_API_LIST.includes(type)
  }

  if (typeof submitType === 'string') {
    return COMMON_API_LIST.includes(submitType)
  }

  return false
}
