import { useModal } from '@ebay/nice-modal-react'
import { useEffect } from 'react'
import { isAndroid, isDesktop, isIOS } from 'react-device-detect'

import { IosDownloadBanner } from '../components/common'

const useIosDownloadBanner = () => {
  const iosDownloadBanner = useModal(IosDownloadBanner)

  useEffect(() => {
    if (
      !isIOS ||
      isAndroid ||
      isDesktop ||
      window.ReactNativeWebView ||
      location.pathname !== '/'
    )
      return
    setTimeout(() => iosDownloadBanner.show(), 2000)
  }, [])
}

export default useIosDownloadBanner
