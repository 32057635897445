import { useTranslation } from 'react-i18next'
import { styled } from '@linaria/react'

const Btn = styled.button`
  display: flex;
  margin: 0 auto 16px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  height: 45px;
  width: 100%;
  background-color: #df0033;
  color: white;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  opacity: 1;
  cursor: pointer;
  font-family: 'Syncopate-Bold';
  text-transform: uppercase;
  &:disabled {
    background-color: #65676a;
    color: #d9d9d9;
  }
  &.secondary {
    background-color: #65676a;
  }
  @media (min-width: 1200px) {
    height: 50px;
    font-size: 16px;
  }
`

const Note = styled.p`
  color: #999;
  font-size: 10px;
  font-style: italic;
  text-align: center;
  margin-bottom: 8px;
  @media (min-width: 1200px) {
    font-size: 12px;
  }
`

const AddBankBtn = (props: { onClick: () => void }) => {
  const { t } = useTranslation('walletManagement')

  return (
    <>
      <Note>{t('bind-now')}</Note>
      <Btn {...props}>+ {t('add-bank-card')}</Btn>
    </>
  )
}

export default AddBankBtn
