import { useModal, type NiceModalHandler } from '@ebay/nice-modal-react'
import { Link } from '@tanstack/router'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@linaria/react'

import useDimensions from '../../hooks/useDimensions'
import type { ApiResponse } from '../../types/api'
import type { WithdrawHistoryObj } from '../../types/wallet-management'
import { api } from '../../utils/api-client'
import closeModal from '../../utils/closeModal'
import { numberWithCommas } from '../../utils/number'
import MessageModal from '../common/message-modal'
import Spinner from '../common/spinner'
import Icon from '../icon'
import CancelTransactionModal from './cancel-transaction-modal'

const Container = styled.div`
  background-color: var(--tertiary);
  border-radius: 4px;
`

const Header = styled.div`
  padding: 0 16px;
  .theme-dark & {
    border-bottom: 1px solid #1f223c;
  }
  .theme-light & {
    border-bottom: 1px solid #fff;
  }
`

const Text = styled.p`
  font-size: 12px;
  line-height: 14px;
  font-family: 'Avenir-Light';
  color: var(--txt);
  &.title {
    font-size: 14px;
  }
  &.bold {
    font-family: 'Avenir-Heavy';
  }
`

const Refresh = styled.button`
  display: flex;
  padding: 12px 16px;
  margin-right: -16px;
  svg {
    fill: var(--txt);
  }
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  &.rotate {
    animation: rotate 1.5s linear infinite;
  }
`

const ScrollList = styled.div`
  padding: 0 16px;
  max-height: calc(100vh - 144px);
  overflow-y: auto;
  @media (min-width: 1200px) {
    max-height: calc(100vh - 450px);
    /* width */
    &::-webkit-scrollbar {
      width: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border: 5px solid transparent;
      border-radius: 9px;
      background-clip: content-box;
    }

    .theme-dark &::-webkit-scrollbar-thumb {
      background-color: #000;
    }

    .theme-light &::-webkit-scrollbar-thumb {
      background-color: #ccc;
    }
  }
`

const HistoryItem = styled.div`
  padding: 12px 0;
  .theme-dark & {
    border-bottom: 1px solid #1f223c;
  }
  .theme-light & {
    border-bottom: 1px solid #fff;
  }
`

const Status = styled.p`
  font-size: 12px;
  font-family: 'Avenir-Light';
  font-style: italic;
  color: #878787;
  text-align: right;
  &.success {
    color: #9bdf45;
  }
  &.failed {
    color: #ff4646;
  }
`

const CheckMore = styled.span`
  font-size: 14px;
  font-family: 'Avenir-Light';
  color: #df0033;
  text-align: center;
  padding: 12px 0;
  text-decoration: underline;
  display: block;
`

const Cancel = styled.button`
  font-size: 12px;
  font-family: 'Avenir-Light';
  color: #202632;
  padding: 3px 5px;
  background-color: #fff;
  margin-right: 8px;
`

const ExclamationMark = styled.button`
  display: flex;
  position: relative;
  margin-right: 2px;
  .tooltip {
    max-width: 120px;
    width: max-content;
    padding: 5px;
    border-radius: 2px;
    font-family: 'Avenir-Light';
    font-size: 12px;
    line-height: 14px;
    color: var(--txt);
    position: absolute;
    top: 50%;
    right: calc(100% + 5px);
    transform: translateY(-50%);
    transition: all 0.2s ease-in-out;
    opacity: 0;
    pointer-events: none;
    .theme-dark & {
      background-color: #65676a;
    }
    .theme-light & {
      background-color: #e0e0ec;
    }
  }
  svg {
    .theme-dark & {
      color: #37404f;
    }
    .theme-light & {
      color: #fff;
    }
  }
  &:hover {
    .tooltip {
      opacity: 1;
    }
  }
`

const Empty = styled.div`
  height: 120px;
  p {
    font-size: 12px;
    color: #9a9a9a;
    text-align: center;
    font-family: 'Avenir-Light';
  }
  @media (min-width: 1200px) {
    p {
      font-size: 16px;
    }
  }
`

type WithdrawHistoryProps = {
  closeWalletManagementModal?: () => void
}

const WithdrawHistory = ({
  closeWalletManagementModal,
}: WithdrawHistoryProps) => {
  const cancelTransactionModal = useModal(CancelTransactionModal)
  const messageModal = useModal(MessageModal)
  const { t } = useTranslation(['common', 'cashier', 'walletManagement'])
  const { isDesktop } = useDimensions()
  const [withdrawList, setWithdrawList] = useState<WithdrawHistoryObj[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [cancelLoading, setCancelLoading] = useState(false)

  const fetchList = async () => {
    const reqBody = {
      type: 'record_list',
      record_type: 'debit',
    }
    setIsLoading(true)
    try {
      const response = await api.post<ApiResponse<WithdrawHistoryObj[]>>(
        '/ajax_data.php',
        reqBody,
      )
      const { status, info } = response.data
      if (status === 1) {
        setWithdrawList(info)
      } else {
        setWithdrawList([])
      }
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setWithdrawList([])
      setIsLoading(false)
    }
    setIsFirstLoad(false)
  }

  useEffect(() => {
    fetchList()
  }, [])

  const cancelDebit = async (selectedTransaction: string | null) => {
    const reqBody = {
      type: 'cancel_debit',
      id: selectedTransaction,
    }
    setCancelLoading(true)

    try {
      const response = await api.post<ApiResponse<string>>(
        '/ajax_data.php',
        reqBody,
      )
      const { status, info } = response.data
      if (status === 1) {
        fetchList()
      }
      messageModal.show({
        title: '',
        message: info,
      })
    } catch (error) {
      console.error(error)
    }

    closeModal(cancelTransactionModal as NiceModalHandler)
    setCancelLoading(false)
  }

  const getStatusClassname = useCallback((verifyStatus: number): string => {
    if (verifyStatus === 1 || verifyStatus === 3) return 'success'
    else if (verifyStatus === 2) return 'failed'
    return ''
  }, [])

  const content = useMemo(() => {
    if (isLoading && isFirstLoad)
      return <Spinner containerStyle={{ height: '200px' }} />
    if (withdrawList.length === 0)
      return (
        <Empty className="flx-ctr">
          <p>{t('no-transactions', { ns: 'cashier' })}</p>
        </Empty>
      )
    return (
      <>
        {withdrawList.map(item => (
          <HistoryItem key={item.id} className="flx-btw-ctr">
            <div>
              <Text style={{ marginBottom: '3px' }}>{item.cardNumber}</Text>
              <Text>{item.requestTime}</Text>
            </div>
            <div className="flx-ctr">
              {item.verifyStatus === 0 && (
                <Cancel
                  onClick={() => {
                    cancelTransactionModal.show({
                      isLoading: cancelLoading,
                      onClick: () => cancelDebit(item.id),
                    })
                  }}>
                  {t('cancel', { ns: 'common' })}
                </Cancel>
              )}
              <div>
                <Text
                  className="bold"
                  style={{ marginBottom: '3px', textAlign: 'right' }}>
                  $ {numberWithCommas(parseFloat(item.amount))}
                </Text>
                <Status
                  className={`flx-ctr ${getStatusClassname(
                    item.verifyStatus,
                  )}`}>
                  {item.verifyStatus === 2 && (
                    <ExclamationMark>
                      <Icon id="exclamation-mark" width="10" height="10" />
                      <span className="tooltip">
                        {item.verifyComment
                          ? item.verifyComment
                          : t('contact-support', { ns: 'common' })}
                      </span>
                    </ExclamationMark>
                  )}
                  {item.status}
                </Status>
              </div>
            </div>
          </HistoryItem>
        ))}
        <Link
          to="/profile/financial-transaction"
          search={{ tab: 'withdrawals' }}>
          <CheckMore
            onClick={() =>
              isDesktop &&
              closeWalletManagementModal &&
              closeWalletManagementModal()
            }>
            {t('check-more', { ns: 'walletManagement' })}
          </CheckMore>
        </Link>
      </>
    )
  }, [isLoading, withdrawList])

  return (
    <Container>
      <Header className="flx-btw-ctr">
        <Text className="bold title">
          {t('last-5-transactions', {
            ns: 'walletManagement',
          })}
        </Text>
        <Refresh
          className={isLoading && !isFirstLoad ? 'rotate' : ''}
          onClick={fetchList}>
          <Icon id="refresh" width="16" height="16" />
        </Refresh>
      </Header>
      <ScrollList>{content}</ScrollList>
    </Container>
  )
}

export default WithdrawHistory
