import type { AxiosRequestConfig } from 'axios'

import { ENGLISH_SUBDIRECTORY, SPANISH_SUBDIRECTORY } from '../../constants/api'
import { isCommonApi } from './isCommonApi'
import { isApi2 } from './isApi2'

const language = localStorage.getItem('i18nextLng') ?? 'es'

export function getApiOrigin(axiosRequestConfig: AxiosRequestConfig) {
  let domain = import.meta.env.VITE_API_URL
  if (isCommonApi(axiosRequestConfig))
    domain = import.meta.env.VITE_COMMON_API_URL
  if (isApi2(axiosRequestConfig)) domain = import.meta.env.VITE_API_URL_2

  const subdirectory =
    language === 'en' ? ENGLISH_SUBDIRECTORY : SPANISH_SUBDIRECTORY

  return `${domain}${subdirectory}`
}
