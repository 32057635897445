import { create } from 'zustand'

import { createCommonSlice } from './slices/commonSlice'
import { createCompetitionsSlice } from './slices/competitionsSlice'
import { createPaymentSlice } from './slices/paymentSlice'
import { createSportsSlice } from './slices/sportsSlice'
import { createUserSlice } from './slices/userSlice'
import type { AllSlices } from './types'
import { createSettingsSlice } from './slices/settingsSlice'

export const useBoundStore = create<AllSlices>()((...a) => ({
  common: createCommonSlice(...a),
  competitions: createCompetitionsSlice(...a),
  payment: createPaymentSlice(...a),
  sports: createSportsSlice(...a),
  user: createUserSlice(...a),
  settings: createSettingsSlice(...a),
}))
