import { useModal } from '@ebay/nice-modal-react'
import { styled } from '@linaria/react'
import { Link, useMatches } from '@tanstack/router'
import { useTranslation } from 'react-i18next'

import halo from '../../assets/common/halo.svg'
import useGeoCheck from '../../hooks/useGeoCheck'
import { useBoundStore } from '../../store/store'
import LoginModal from '../common/log-in-modal'
import Icon from '../icon'

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 20;
  background-color: var(--primary);
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 0 12px;
  height: 60px;
  align-items: center;
  width: 100%;
`

const Tab = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    margin-bottom: 2px;
    position: relative;
    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    .halo {
      position: absolute;
      top: 0;
      left: 0;
      width: 40px;
      height: 40px;
      opacity: 0;
      transition: all 0.2s ease-in-out;
      animation: rotate 2s linear infinite;
    }
  }
  svg {
    width: 25px;
    height: 25px;
  }
  &.active {
    p {
      color: #df0033;
    }
    .halo {
      opacity: 1;
    }
  }
  p {
    font-size: 8px;
    font-family: 'Syncopate-Bold';
    text-transform: uppercase;
    color: var(--txt);
    text-align: center;
  }
`

const BottomNav = () => {
  const pending_bind = useBoundStore(state => state.user.pending_bind)
  const setIsPendingDepositVisible = useBoundStore(
    state => state.common.setIsPendingDepositVisible,
  )
  const username = useBoundStore(state => state.user.username)
  const { geoCheck } = useGeoCheck()
  const logInModal = useModal(LoginModal)
  const { t } = useTranslation('drawer')
  const matches = useMatches()
  const fullPath = matches.slice(-1)[0].route.fullPath

  const TABS = [
    {
      icon: <Icon id="sports" />,
      label: t('sports'),
      link: +pending_bind === 0 || +pending_bind === 2 ? undefined : '/sports',
      onLinkClick: () => {
        if (+pending_bind === 0 || +pending_bind === 2)
          setIsPendingDepositVisible(true)
      },
      isProtected: false,
    },
    {
      icon: <Icon id="casino" />,
      label: 'CASINO',
      link: '/casino',
      onLinkClick: null,
      isProtected: false,
    },
    {
      icon: <Icon id="promotions" />,
      label: t('promotions'),
      link: '/promotions',
      isProtected: false,
    },
    {
      icon: <Icon id="free-bonus" />,
      label: t('bonus'),
      link: '/profile/free-bonus',
      onLinkClick: null,
      isProtected: true,
    },
  ] as const

  return (
    <Container>
      {TABS.map(tab => (
        <Link
          to={tab.isProtected && !username ? undefined : tab.link}
          key={tab.label}>
          <Tab
            className={tab.link && fullPath.includes(tab.link) ? 'active' : ''}
            onClick={() => {
              if (tab.isProtected && !username) {
                geoCheck(logInModal.show)
              }
            }}>
            <div className="icon">
              <img src={halo} className="halo" />
              {tab.icon}
            </div>
            <p>{tab.label}</p>
          </Tab>
        </Link>
      ))}
    </Container>
  )
}

export default BottomNav
