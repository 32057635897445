// import { useModal } from '@ebay/nice-modal-react'

// import { MessageModal } from '../components/common'
// import LoadingModal from '../components/common/loading-modal'
// import { useBoundStore } from '../store/store'
// import type { ApiResponse } from '../types/api'
// import { api } from '../utils/api-client'
// import closeModal from '../utils/closeModal'

const useGeoCheck = () => {
  // const username = useBoundStore(state => state.user.username)
  // const messageModal = useModal(MessageModal)
  // const loadingModal = useModal(LoadingModal)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const geoCheck = async (onSuccess: () => void, onFail?: () => void) => {
    onSuccess()
    // if (username) return onSuccess()

    // try {
    //   loadingModal.show()

    //   const response = await api.post<ApiResponse<string>>(
    //     '/geolocation.php',
    //     {},
    //   )
    //   const { status, info } = response.data
    //   if (status === 0) {
    //     onFail?.()
    //     messageModal.show({ message: info })
    //   } else {
    //     onSuccess()
    //   }
    // } catch (error) {
    //   messageModal.show({ message: 'FetchServerError: Network Error' })
    //   console.error(error)
    // } finally {
    //   closeModal(loadingModal)
    // }
  }

  return { geoCheck }
}

export default useGeoCheck
