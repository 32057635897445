import { Provider as NiceModalProvider } from '@ebay/nice-modal-react'
import { Outlet, RootRoute } from '@tanstack/router'

import CustomerService from '../components/common/customer-service'
import BottomNav from '../components/layouts/bottom-nav'
import useAnnouncements from '../hooks/useAnnouncements'
import useDimensions from '../hooks/useDimensions'
import useScrollToTop from '../hooks/useScrollToTop'
import { ErrorPage } from '../components/common'
import useContinue from '../hooks/useContinue'
import useTheme from '../hooks/useTheme'
import useSettings from '../hooks/useSettings'
import useAndroidDownloadBanner from '../hooks/useAndroidDownloadBanner'
import useKwaiq from '../hooks/useKwaiq'
import useDepositEvents from '../hooks/useDepositEvents'
import useIosDownloadBanner from '../hooks/useIosDownloadBanner'

export const rootRoute = new RootRoute({
  component: () => {
    const { isDesktop } = useDimensions()
    useTheme()
    useContinue()
    useAnnouncements()
    useScrollToTop()
    useSettings()
    useAndroidDownloadBanner()
    useIosDownloadBanner()
    useKwaiq()
    useDepositEvents()

    return (
      <NiceModalProvider>
        <CustomerService />
        <Outlet />
        {!window.ReactNativeWebView &&
          !isDesktop &&
          !location.pathname.includes('/game') && <BottomNav />}
      </NiceModalProvider>
    )
  },
  errorComponent: ErrorPage,
})
