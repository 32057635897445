import { Route, lazy } from '@tanstack/router'
import { rootRoute } from './root'

const NotFound = lazy(() => import('../components/common/not-found'))

export const notFoundRoute = new Route({
  getParentRoute: () => rootRoute,
  component: NotFound,
  path: '/*',
})
