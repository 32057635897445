import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { Link } from '@tanstack/router'
import { useTranslation } from 'react-i18next'
import { styled } from '@linaria/react'

import useDimensions from '../../hooks/useDimensions'
import type { AnnouncementObj } from '../../types/announcement'
import closeModal from '../../utils/closeModal'
import AllAnnouncementsModal from './all-announcements-modal'
import Modal from './modal'

const ViewAll = styled.span`
  width: 120px;
  text-align: center;
  font-size: 18px;
  padding: 7px 0;
  color: #fff;
  border-radius: 22px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  @media (min-width: 1200px) {
    font-size: 20px;
    padding: 10px 0;
    width: 150px;
    border-radius: 26px;
  }
  background-color: #df0033;
`

const Button = styled.button`
  width: 120px;
  text-align: center;
  font-size: 18px;
  padding: 7px 0;
  color: #fff;
  border-radius: 22px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  @media (min-width: 1200px) {
    font-size: 20px;
    padding: 10px 0;
    width: 150px;
    border-radius: 26px;
  }
  border-color: #65676a;
  background-color: #65676a;
`

const Text = styled.p`
  font-size: 16px;
  font-family: 'Avenir-Light';
  color: var(--txt);
  margin-bottom: 5px;
  text-align: center;
  word-wrap: break-word;
  @media (min-width: 1200px) {
    font-size: 18px;
  }
`

type AnnouncementModalProps = {
  announcement: AnnouncementObj
}

const AnnouncementModal = ({ announcement }: AnnouncementModalProps) => {
  const modal = useModal()
  const allAnnouncementsModal = useModal(AllAnnouncementsModal)
  const { t } = useTranslation('announcementModal')
  const { isDesktop } = useDimensions()

  return (
    <Modal id="announcement-modal">
      <div style={{ padding: '0 20px' }}>
        <Text
          style={{
            fontSize: 24,
            fontFamily: 'Avenir-Heavy',
            marginBottom: 20,
          }}>
          {t('announcement')}
        </Text>
        <Text>{announcement?.content}</Text>
      </div>
      <div className="flx-ctr" style={{ marginTop: '45px', gap: '15px' }}>
        <Button className="secondary" onClick={() => closeModal(modal)}>
          {t('cancel')}
        </Button>
        <Link to={isDesktop ? undefined : '/announcements'}>
          <ViewAll
            onClick={async () => {
              closeModal(modal)
              isDesktop && allAnnouncementsModal.show()
            }}>
            {t('view-all')}
          </ViewAll>
        </Link>
      </div>
    </Modal>
  )
}

export default NiceModal.create(AnnouncementModal)
